'use client';
const { createContext, useEffect, useState } = require("react");

import { useHttp } from "@hooks/useHttp";
import { API_ENDPOINT } from '@constants/variables/apiEndpoint';

export const SellerContext = createContext({ loggedIn: { isLoggedIn: false, setIsLoggedIn: () => { }, getDataUser: () => { } }, seller: {}, setSeller: () => { }, })

export function SellerProvider({ children }) {
  const { GET } = useHttp();
  const [seller, setSeller] = useState({})
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    getDataUser()
  }, [])

  const getDataUser = async () => await GET({
    url: API_ENDPOINT.sellers.currentSeller, options: {
      callback: ({ isLoggedIn, seller }) => {
        setSeller(seller)
        setIsLoggedIn(isLoggedIn)
      }, dontShowMessage: true, dontShowLoading: true
    }
  })

  return (
    <SellerContext.Provider value={{ loggedIn: { isLoggedIn, setIsLoggedIn }, getDataUser, seller, setSeller }}>
      {children}
    </SellerContext.Provider>
  )
}